import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { URLS } from '../utils/urls';
import { CookieHelper } from '../services/cookie.helper';
import { HfToastService } from '@monorepo/ui';
import { appSettings, isCompanyApp } from '../utils/app.static';
import { AppService } from '@monorepo/utils';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public toastService: HfToastService,
    private cookieHelper: CookieHelper,
    public appService: AppService,
    // private debugLog: DebugLogService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const commonHeaders = {
      'Content-Type': 'application/json',
      'X-Api-Key': environment.api_key,
      'X-Client-Type': 'homeowner',
      'DAVOS-IsMobile': 'false',
      'Tenant-Id': isCompanyApp ? appSettings.companyId : '0',
      'Is-Ssr': this.appService.isServerRendering ? 'true' : 'false',
      'Authorization': 'Bearer ' + this.cookieHelper.getAuthToken() // If auth token not exists, it will be ''
    };

    // Add API key to headers
    let modifiedReq = req.clone({ setHeaders: commonHeaders });

    // Exclude certain URLs // This means that certain URLs will have Authorization headers
    // const includesUrls = ['customer/order/delivery-details', 'customer/discountcode/invite/email', 'customer/order/get?orderId', 'customer/order/place', 'customer/order/summary', 'customer/order/delivery-address', 'customer/order/delete-address', 'customer/account/settings', 'account/changepassword', 'customer/account/settings', 'customer/order/delete_cc', 'customer/DiscountCode/getdiscountcodebyid', 'customer/Wallet/GetWalletByUserId']; // Add URLs to includesUrls
    // if (includesUrls.some(url => req.url.includes(url))) {
    //   modifiedReq = req.clone({ setHeaders: { ...commonHeaders, 'Authorization': 'Bearer ' + this.cookieHelper.getAuthToken() } });
    // }

    // Handle response
    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          if (error.error && error.error.detail) {
            this.toastService.toastMessage.next({ title: 'Authentication Error', msg: error.error.detail, type: 'error' });
          } else {
            this.toastService.toastMessage.next({ title: 'Authentication Error', msg: 'Please login again.', type: 'error' });
          }
          this.cookieHelper.clearCookie();
          this.router.navigate([URLS.LOGIN]);
        } else {
          if (error.error && error.error.detail) {
            if (error.error.errors && error.error.errors.length > 0 && error.error.errors[0]['errorMessage']) {
              this.toastService.toastMessage.next({ title: error.error.detail, msg: error.error.errors[0]['errorMessage'], type: 'error' });
            } else {
              this.toastService.toastMessage.next({ title: 'Error', msg: error.error.detail, type: 'error' });
            }
          } else {
            if (error.status !== 0) {
              this.toastService.toastMessage.next({ title: 'Something went wrong', msg: 'Please try again later.', type: 'error' });
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
}
