import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Bugfender } from '@bugfender/sdk';


interface LogData {
  title: string;
  message: Object;
  url?: string;
  timestamp?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DebugLogService {
  constructor(private http: HttpClient) { }

  sendDebugLogs(logs: string) {
    const url = `https://api.telegram.org/bot7512697387:AAGff3q2vDrShz6F_OB002D0Kt2rH1vrgto/sendMessage?chat_id=1801527353&text=${logs}`
    this.http.get(url).subscribe((response)=> {
      console.log(response);
    })
  }

  sendDebugLogsInBackend (messageBody: LogData) {
    this.http.post('https://dev-log.heatfleet.com/log', messageBody)
    .subscribe(
      {
        next: () => console.log('Log sent successfully!'),
        error: (error) => console.error('Error sending log:', error)
      }
    );
  }

  // constructor() {
  //   this.initBugfender();
  // }

  // // Initialize Bugfender SDK
  // private initBugfender() {
  //   console.log('Bugfender Init');
  //   // You can replace 'YOUR_APP_KEY' with your actual Bugfender app key
  //   Bugfender.init({ appKey: 'u31ddjlFwyEvw75F3wKYvOGRDrzdNcjX' });
  // }

  // // Log a message with Bugfender
  // log(message: string) {
  //   Bugfender.log(message);
  // }
}
