import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { AppService } from './app.service';

@Injectable()
export class CameraService {
  constructor(public appService: AppService) { }

  async takePhoto(): Promise<string | null> {
    try {
      const source = this.appService.isApp
        ? CameraSource.Prompt
        : CameraSource.Photos; // Use Photos on the web

      const photo: Photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,  // Get the photo as a Base64-encoded string
        source,
      });

      // Return the base64 string with a data URL prefix
      return `data:image/jpeg;base64,${photo.base64String}` || null;
    } catch (error) {
      console.error('Camera error:', error);
      return null;
    }
  }

  async pickImage(): Promise<string | null> {
    try {
      const result = await Camera.pickImages({
        quality: 90,
        limit: 1,
      });

      if (result.photos.length > 0) {
        // Get the first selected photo
        const photo = result.photos[0];
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();

        // Convert the Blob to a Base64 string
        return await this.convertBlobToBase64(blob);
      }

      return null; // Return null if no image is selected
    } catch (error) {
      console.error('Error picking image:', error);
      return null;
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
