<!-- @if (showToast) {
<div class="hf-toast" [ngClass]="{'hf-toast-error-bg': toastType === 'error',
    'hf-toast-success-bg': toastType === 'success',
    'hf-toast-warning-bg': toastType === 'warning',
    'hf-toast-info-bg': toastType === 'info'}">
    @if (toastType === 'error') {
    <img src='/assets/icons/error.svg' alt="Toast error icon" title="Toast error icon">
    } @else if (toastType === 'success') {
    <img src='/assets/icons/success.svg' alt="Toast success icon" title="Toast success icon">
    } @else if (toastType === 'warning') {
    <img src='/assets/icons/warning.svg' alt="Toast warning icon" title="Toast warning icon">
    } @else if (toastType === 'info') {
    <img src='/assets/icons/info.svg' alt="Toast info icon" title="Toast info icon">
    }
    <div class="hf-toast-body">
        <div class="hf-toast-header">
            <p class="hf-toast-title-text">{{toastTitle}}</p>
            <img (click)="hideToast()" src='/assets/icons/close-gray.svg' alt="Toast close icon"
                title="Toast close icon" style="cursor: pointer;">
        </div>
        <p class="hf-toasts-content-text" [innerHTML]="toastMessage"></p>
    </div>
</div>
} -->

@if (showToast) {
    <div class="hf-toast">
        <div class="hf-toast-body">
            <div class="hf-toast-header">
                <p class="hf-toast-title-text hf-dark-font">{{toastTitle}}</p>
                <img (click)="hideToast()" src='/assets/icons/close-gray.svg' alt="Toast close icon"
                    title="Toast close icon" style="cursor: pointer;">
            </div>
            <p class="hf-toasts-content-text" [innerHTML]="toastMessage"></p>
        </div>
    </div>
}