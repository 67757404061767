import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable()
export class ImageCompressionService {
    constructor(private imageCompress: NgxImageCompressService) { }

    /**
     * Compress an image from a File or Base64 string to a target size in MB.
     * @param input - The File object or Base64 string.
     * @param maxSizeMB - The target maximum size in MB.
     * @returns A promise containing the compressed image as a base64 string.
     */
    compressImage(input: File | string, maxSizeMB: number): Promise<string> {
        return new Promise((resolve, reject) => {
            // If input is already a base64 string, directly compress
            if (typeof input === 'string') {
                this.compressBase64Image(input, maxSizeMB, resolve, reject);
            } else {
                // Convert File to base64 and then compress
                const fileReader = new FileReader();
                fileReader.onload = (event: any) => {
                    const base64Image = event.target.result; // File -> base64
                    this.compressBase64Image(base64Image, maxSizeMB, resolve, reject);
                };
                fileReader.onerror = () => reject('Failed to read file');
                fileReader.readAsDataURL(input);
            }
        });
    }

    /**
     * Helper function to compress a base64-encoded image.
     * @param base64Image - The base64 image string.
     * @param maxSizeMB - The target maximum size in MB.
     * @param resolve - Promise resolve callback.
     * @param reject - Promise reject callback.
     */
    private compressBase64Image(
        base64Image: string,
        maxSizeMB: number,
        resolve: (value: string) => void,
        reject: (reason?: any) => void
    ) {
        // const sizeMB = this.getImageSizeInMB(base64Image);
        // If image size is already below maxSizeMB, return the original image
        // if (sizeMB <= maxSizeMB / 4) {
        //     resolve(base64Image);
        //     return;
        // }

        const orientation = -1; // Auto orientation
        const quality = 40; // Start with 50% quality

        this.imageCompress
            .compressFile(base64Image, orientation, quality, quality)
            .then((compressedImage) => {
                const sizeMB = this.getImageSizeInMB(compressedImage);

                if (sizeMB <= maxSizeMB) {
                    resolve(compressedImage);
                } else {
                    reject(
                        `Compressed image is still too large (${sizeMB.toFixed(2)} MB).`
                    );
                }
            })
            .catch((error) => reject(`Compression error: ${error}`));
    }

    /**
     * Calculate the size of a base64-encoded image in MB.
     * @param base64String - The base64 image string.
     * @returns The size in MB.
     */
    getImageSizeInMB(base64String: string): number {
        const stringLength = base64String.length - 'data:image/jpeg;base64,'.length;
        const sizeInBytes = (4 * Math.ceil(stringLength / 3)) * 0.5624896;
        return sizeInBytes / (1024 * 1024);
    }
}
